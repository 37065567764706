import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { AppUser } from '@/models/app-user-dto'
import Commons from '@/components/shared/Helpers/commons'
import { AuthService } from '@/services/auth-service'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { Subscription } from 'rxjs'
import { langues } from '../../../../views/Patients/Dossier/Constants'
import { PatientService } from '@/services/patient-service'
import { DossierAssurance } from '../../../../views/Patients/Static'
import DocPreviewBase from '../DocPreviewBase.vue'
import { EvaluationInitialeHelper, EvaluationTblStructureModel } from '@/views/Patients/Overview/EvaluationInitiale/EvaluationInitialHelper'
import { EvaluationInitialeModel, EvaluationReportModel } from '@/models/consultation-model'
import AddressBloc from '../AddressBloc.vue'
import { AgendaService } from '@/services/agenda-service'

@Component({
  components: {
    DocPreviewBase,
    AddressBloc
  }
})
export default class EvaluationFormDoc extends Vue {
  @Prop({ default: {} })
  public dossierAssurance!: DossierAssurance

  @Prop({ default: [] })
  public users!: AppUser[]

  @Prop({ default: [] })
  public reseauPro!: any[]

  private subscriptions: Subscription[] = []
  private userService = AuthService.getInstance()
  private patientService = PatientService.getInstance()
  private agendaService = AgendaService.getInstance()
  private selectedDossier: dossierPatientModel| any = null
  public patientFullName = ''
  public userFullName = ''
  public medecinId = ''
  public medecin: any = null
  public langues = langues
  public insurance = ''
  public assurances: any[] = []
  public addressBloc: any[] = []
  public infirmiereId = ''
  public genreDePrestations = ''
  public dateStart = Commons.FormatDateForInputField(new Date())
  public remarque = ''
  public evaluationInit: EvaluationTblStructureModel[] = []
  public evaluations: EvaluationInitialeModel[] = []
  public consulationType!: number
  public appointmentId!: number
  public loaded = false
  public duration = '6 mois'

  public dossierId = ''
  public prescriptionTypeId = 0
  public defaultInfirmiereId = ''

  public async mounted () {
    this.subscriptions.push(this.patientService.assuranceList$.subscribe((assurance: any[]) => {
      this.assurances = assurance
      if (!assurance.length) {
        this.patientService.getAllAssurance()
      }
      this.getDossierAssurance()
    }))

    this.subscriptions.push(this.patientService.evaluationReportModelSelected$.subscribe(async (evaluationReportModel: EvaluationReportModel) => {
      this.evaluations = evaluationReportModel.evaluations as EvaluationInitialeModel[]
      this.consulationType = evaluationReportModel.consultationType as number
      this.appointmentId = evaluationReportModel.appointmentId as number
      this.prescriptionTypeId = evaluationReportModel.prescriptionTypeId as number
      this.dossierId = evaluationReportModel.dossierId as string
      if (this.defaultInfirmiereId === '') {
        this.infirmiereId = evaluationReportModel.infirmiereId as string
      }
      if (evaluationReportModel.prescriptionDoctor) {
        this.addDoctorToResauPro(evaluationReportModel.prescriptionDoctor)
      } else {
        const prescriptionDoctor = await this.agendaService.getAppointmentDoctor(evaluationReportModel.appointmentId ?? 0)
        if (prescriptionDoctor) {
          this.addDoctorToResauPro(prescriptionDoctor)
        }
      }
    }))

    this.defaultInfirmiereId = await this.patientService.getDefaultEvaluationInfirmiereId()
    if (this.defaultInfirmiereId !== '') {
      this.infirmiereId = this.defaultInfirmiereId
    }

    if (this.dossierId) {
      this.selectedDossier = await this.patientService.getDossierPatientById(this.dossierId)
      this.getDossierAssurance()
    }
    if (!this.selectedDossier) {
      console.error('Unable to generate the report')
      return
    }

    this.patientFullName = this.selectedDossier.fullName

    this.userFullName = this.userService.fullName
    this.evaluationInit = EvaluationInitialeHelper.getTblStructure()
    this.mapDateEvaluationInitTblStruture(this.evaluations)

    switch (this.consulationType) {
      case 3:// prediabete
        this.genreDePrestations = 'Conseils au patient prédiabétique'
        break
      case 4:// pediatrie
        this.genreDePrestations = `Conseils à l'enfant diabétique et son entourage`
        break
      case 5:// podo
        this.genreDePrestations = 'Conseils et soins pour diabétiques'
        break
    }
    this.loaded = true
  }

  private mapDateEvaluationInitTblStruture (data: EvaluationInitialeModel[]) {
    if (data) {
      data.map(initRow => {
        const tableRowInfo = this.evaluationInit.find(x => x.id === initRow.evaluationTypeId)
        if (initRow.frequencyTypeId && initRow.value) {
          if (tableRowInfo?.values) tableRowInfo.values[initRow.frequencyTypeId - 1].value = initRow.value.toString()
        }
      })
    }
  }

  public getDossierAssurance () {
    if (this.selectedDossier?.guid) {
      this.mapDossierAssuranceResponse(this.dossierAssurance)
    }
  }

  private mapDossierAssuranceResponse (dossierResponse: DossierAssurance) {
    this.dossierAssurance = dossierResponse
    this.mapInsuranceName()
  }

  private mapInsuranceName () {
    this.insurance = this.assurances.find(x => x.id === this.dossierAssurance.assuranceId)?.assuranceName
  }

  public doctorChanged (doctorId: string) {
    this.medecin = this.reseauPro.find(x => x.reseauMedicalExtra?.reseauId === doctorId)
    this.$emit('doctorChanged', this.medecin?.fullname)
  }

  get languages () {
    if (this.selectedDossier?.languagesEnumIdList) {
      this.selectedDossier.languagesEnumIdList = Commons.transformLanguages(this.selectedDossier.languagesEnumIdList)
      return this.langues.filter(l => this.selectedDossier.languagesEnumIdList.some(x => Number(x) === Number(l.value))).map(a => a.text).join(", ")
    }
    return ''
  }

  get formattedInfirmiere () {
    const found = this.users.find(x => x.id === this.infirmiereId)
    if (found) {
      return `<strong>${Commons.newlines2br(found.fullName)}</strong><br /><em>${found.function ?? ''}</em>`
    }
    return ''
  }

  private addDoctorToResauPro (doctor: any) {
    const found = this.reseauPro.find(x => x.reseauMedicalExtra?.reseauId === doctor.reseauMedicalExtra?.reseauId)
    if (!found) {
      this.reseauPro.push(doctor)
    }
    // we want to select a doctor from the reseau by default, and not the doctor from the prescription
    // the doctor from the prescription will have the value '00000000-0000-0000-0000-000000000000' for 'reseauAutreId'
    // unless it's also part of the reseau of the patient
    const defaultDoctorCandidate = this.reseauPro.find(x => x.reseauAutreId !== '00000000-0000-0000-0000-000000000000')
    if (defaultDoctorCandidate) {
      this.medecin = defaultDoctorCandidate
      this.medecinId = this.medecin.reseauMedicalExtra?.reseauId
      this.doctorChanged(this.medecinId)
    }
  }
}
