import { Component, Vue, Watch } from 'vue-property-decorator'
import DocumentPreview from '@/components/shared/DocumentPreview/EvaluationFormDoc/EvaluationFormDoc.vue'
import Commons from '@/components/shared/Helpers/commons'
import { Subscription } from 'rxjs'
import { PatientService } from '@/services/patient-service'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { ValidationObserver } from 'vee-validate'
import ReportHelper from '@/components/shared/Helpers/ReportHelpers'
import { EvaluationReportModel } from '@/models/consultation-model'
import { AppUser } from '@/models/app-user-dto'
import { AuthService } from '@/services/auth-service'
import { ErrorService } from '@/services/error.service'
import { DossierAssurance } from '../../Static'
import Alert from '@/components/shared/Alert.vue'

@Component({
  components: {
    Alert,
    DocumentPreview,
    ValidationObserver
  }
})
export default class EvaluationForm extends Vue {
  private subscriptions: Subscription[] = []
  private patientService = PatientService.getInstance()
  private userService = AuthService.getInstance()
  private selectedDossier!: dossierPatientModel
  public patientFullName = ''
  public loading = false
  public saving = false
  public evaluationReportModel!: EvaluationReportModel
  public errorMessages: string[] = []
  public allInfirmieres: AppUser[] = []
  public dossierAssurance: DossierAssurance = {}

  public reseauPro: any[] = []
  private readyInfirmiere = false
  private readyDossierAssurance = false
  public doctorName = ''

  public async mounted () {
    this.loading = true
    this.readyInfirmiere = false
    this.readyDossierAssurance = false

    this.saving = false
    Commons.updateDossierName()
    this.subscriptions.push(this.patientService.evaluationReportModelSelected$.subscribe((evaluationReportModel: EvaluationReportModel) => {
      this.evaluationReportModel = evaluationReportModel
    }))
    if (this.evaluationReportModel) {
      await this.patientService.getDossierPatientById(this.evaluationReportModel.dossierId!)
        .then(data => {
          this.selectedDossier = data
        })
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
    }
    if (!this.selectedDossier) {
      this.loading = false
      this.errorMessages = ["Impossible d'afficher le rapport, les données du patient n'ont pas pu être récupérées."]
      return
    }

    this.patientFullName = this.selectedDossier.fullName
    this.getAllInfirmiereUsers()
    await Commons.getDossierReseau(this.selectedDossier.guid, this.errorMessages, this.reseauPro)
    this.getDossierAssurance()
  }

  private updateReadyState () {
    this.loading = !this.readyInfirmiere || !this.readyDossierAssurance
  }

  @Watch('readyInfirmiere')
  public readyInfirmiereChanged () {
    this.updateReadyState()
  }

  @Watch('readyDossierAssurance')
  public readyDossierAssuranceChanged () {
    this.updateReadyState()
  }

  public getAllInfirmiereUsers () {
    this.readyInfirmiere = false
    this.userService.getAllInfirmiereGroupUsers().then((infirmieres) => {
      this.allInfirmieres = infirmieres
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyInfirmiere = true
    })
  }

  public async getDossierAssurance () {
    this.readyDossierAssurance = false
    if (this.selectedDossier?.guid) {
      await this.patientService.getDossierAssuranceInfo(this.selectedDossier.guid).then(data => {
        this.dossierAssurance = data
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.readyDossierAssurance = true
      })
    }
  }

  public async savePDF () {
    this.saving = true
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      const documentPreview = this.$refs.documentpreview as any
      let prescriptionIdToUse = ''
      let appointmentIdToUse: number|undefined
      if (this.evaluationReportModel.appointmentId && this.evaluationReportModel.appointmentId > 0) {
        appointmentIdToUse = this.evaluationReportModel.appointmentId!
      } else if (this.evaluationReportModel.prescriptionId) {
        prescriptionIdToUse = this.evaluationReportModel.prescriptionId
      }
      const model = ReportHelper.GeneratePDFModel(7, `Prestations requises-${this.patientFullName}-${new Date().toLocaleDateString()}.pdf`, documentPreview,
        prescriptionIdToUse, this.doctorName, appointmentIdToUse)
      if (this.selectedDossier.guid) {
        await ReportHelper.UploadDocument(model, this.errorMessages, this.$router, this.selectedDossier.guid, this.isRenewal)
      }
    } else {
      const merged = Object.assign(this.$refs, (this.$refs.documentpreview as Vue).$refs)
      Commons.focusFirstComponentWithError(observer, merged, 'Ref')
    }
    this.saving = false
  }

  public get isRenewal () {
    return this.evaluationReportModel?.prescriptionTypeId === 2
  }

  public goBack () {
    if (this.isRenewal) {
      sessionStorage.setItem('replayLastPendingSearch', JSON.stringify(true))
    } else {
      sessionStorage.removeItem('replayLastPendingSearch')
    }
    this.$router.go(-1)
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public doctorChanged (doctorName) {
    this.doctorName = doctorName
  }
}
